<template>
    <modal-lateral ref="informacion" titulo="Información  de la tienda">
        <div class="f-15 px-4">
            <div class="row mx-0 justify-center">
                <img class="br-10 border bg-whitesmoke" width="150" height="150" src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTSz_VcU4Gj3d3ffPf8HUPRjywcC_zviK5pwQ&usqp=CAU" alt="" />
            </div>
            <div class="row mx-0 justify-center f-20">
                {{$config.vendedor}} Neto
            </div>
            <div class="row mx-0">
                Estado de cuenta
            </div>
            <div class="row mx-0 align-items-center my-3">
                <div class="bg-gr-general br-20 p-1 px-2 text-white mr-2">
                    Pagado
                </div>
                <p>01 Jul. 2020</p>
            </div>
            <div v-for="(data, idx) in datos_tiendas" :key="idx" class="row mx-0 mt-2">
                <b class="mr-2">{{ data.nombre }}: </b>
                <p> {{ data.valor }}</p>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {
            datos_tiendas:[
                { nombre: 'Plan', valor: 'VIP' },
                { nombre: 'Tipo', valor: 'Micromercado' },
                { nombre: 'Dueño', valor: 'Ana María Villegas' },
                { nombre: 'Dirección', valor: 'Cra. 25 #45-48' },
                { nombre: 'Número de celular', valor: '+57 300 123 4567' },
                { nombre: 'Correo electrónico', valor: 'netrotienda@gmail.com' },
                { nombre: 'Descripción', valor: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur, et!' },
            ]
        }
    },
    methods: {
        toggle(){
            this.$refs.informacion.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
